export default {
  PACKAGES_LIST: "Packages List",
  ADD_PACKAGE: "Add Package",
  PACKAGE_ADDED: "Package Added",
  EDIT_PACKAGE: "Edit Package",
  PACKAGE_UPDATED: "Package Updated",
  FREQUENCY: "Frequency",
  DEFAULT_ROLE: "Default Role",
  MAXIMUM_USERS: "Max Users",
  DELETE_THIS_PACKAGE: "Delete This Package?",
  PACKAGE_DELETED: "Package Deleted",
};
