export default {
  DEFAULT_MODULE: "Application",
  ADMIN: "Administration",
  CRM_MODULE: "Crm",
  SALES_MODULE: "Sales",
  PURCHASES_MODULE: "Purchases",
  INVENTORY_MODULE: "Inventory",
  FILE_EXPLORER_MODULE: "File Explorer",
  ADMIN_MODULE: "Administration",
  DASHBOARD: "Dashboard",
  RESELLER_MODULE: "Reseller",
  AUCTION_MODULE: "Auctions",
};
