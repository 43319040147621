export default {
  USERS_LIST: "Users List",
  ADD_USER: "Add User",
  USER_ADDED: "User Added",
  IS_STAFF: "Admin",
  ALL_IS_STAFF: "All Types",
  AUTHORIZATIONS: "Authorizations",
  EDIT_USER: "Edit User",
  USER_UPDATED: "User Updated",
  DELETE_THIS_USER: "Delete this user?",
  USER_DELETED: "User Deleted",
  ACTIVE: "Active",
  ACTIVATE: "Activate",
  DEACTIVATE: "Deactivate",
  USER_ACTIVATED: "User Activated",
  USER_DEACTIVATED: "User Deactivated",
};
