export default {
  INVOICES_LIST: "Invoices List",
  ADD_INVOICE: "Add Invoice",
  ADD_INVOICE_ITEM: "Add Line",
  INVOICE_ADDED: "Invoice Added",
  OWNER: "Owner",
  EDIT_INVOICE: "Edit Invoice",
  INVOICE_UPDATED: "Invoice Updated",
  BILLING_ADDRESS: "Billing Address",
  EXPIRATION_TIME: "Expiration Time",
  ITEM_TYPE: "Type",
  SELECT_PRODUCTS: "Choose Product",
  SELECT_SUBSCRIPTIONS: "Choose Subscription",
  SELECT_PACKAGES: "Choose Package",
  DELETE_INVOICE_ITEM: "Delete Line",
  UPDATE_INVOICE_ITEM: "Edit Line",
  INVOICE_ITEM_DELETED: "Line Deleted",
  INVOICE_ITEM_ADDED: "Line Added",
  INVOICE_ITEM_EDITED: "Line Edited",
  SEND: "Send",
  CANCEL: "Cancel",
  REFUND: "Refund",
  ADD_PAYMENT: "Add Payment",
  INVOICE_SENT: "Invoice Sent",
  SELECT_USERS: "Choose User",
  SELECT_CUSTOMERS: "Choose Customer",
  SELECT_ORGANIZATIONS: "Choose Organization",
  DELETE_THIS_INVOICE: "Delete This Invoice?",
  INVOICE_DELETED: "Invoice Deleted",
  CANCEL_INVOICE_QUESTION: "Cancel Invoice?",
  INVOICE_CANCELED: "Invoice Canceled",
};
