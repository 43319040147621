export default {
  CONTACTS_LIST: "Contacts list",
  ADD_CONTACT: "Add contact",
  CONTACT_ADDED: "Contact added",
  EDIT_CONTACT: "Update contact",
  CONTACT_UPDATED: "Contact mis a jour",
  DELETE_THIS_CONTACT: "Delete this contact ?",
  CONTACT_DELETED: "Contact deleted",
  MISTER: "MR",
  MADAM: "MME",
};
