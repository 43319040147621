export default {
  SUBSCRIPTIONS_LIST: "Subscriptions list",
  ADD_SUBSCRIPTION: "Add subscription",
  SUBSCRIPTION_ADDED: "Subscription added",
  EDIT_SUBSCRIPTION: "Edit subscription",
  SUBSCRIPTION_UPDATED: "Subscription updated",
  START_TIME: "Start time",
  END_TIME: "End time",
  ACTIVE: "Active",
  EXPIRED: "Expired",
  RENEW: "Renew",
  SUBSCRIPTION_INVOICE_ADDED: "Renewal invoice added",
};
