export default {
  TEMPLATES_MAILS_LIST: "Templates mails list",
  ADD_TEMPLATE_MAIL: "Add mail template",
  TEMPLATE_MAIL_ADDED: "Mail template added",
  EDIT_TEMPLATE_MAIL: "Edit mail template",
  TEMPLATE_MAIL_UPDATED: "Mail template updated",
  DELETE_THIS_TEMPLATE_MAIL: "Delete this mail template ?",
  TEMPLATE_MAIL_DELETED: "Mail template supprimée",
  SUBJECT: "Subject",
  TITLE: "Title",
  FROM : "From",
  TO : "To",
  CC : "Cc",
  BCC : "Bcc",
  TYPE : "Type",
  CONTENT: "Content",
  VIEW_TEMPLATE_MAIL: "Mail template",
};
