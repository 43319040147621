export default {
  PROFILE_UPDATED: "Profile updated",
  ENABLE_2FA: "Enable two-factor authentication",
  ENABLE_2FA_AT: "Enabled on",
  DISABLE_2FA: "Disable two-factor authentication",
  DISABLE_2FA_AT: "Disabled on",
  ENABLE_2FA_SUCCESS:
    "Congratulations! You have enabled two-factor verification to further secure your account. Your account is now more secure.",
  DISABLE_2FA_SUCCESS:
    "Two-factor verification has been successfully disabled. You can now sign in without entering an additional code.",
};
