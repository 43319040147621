export default {
  IMPORTS_LIST: "Imports list",
  IMPORTS_PREVIEW: "Preview the import",
  ADD_IMPORT: "Importing data",
  IMPORT_ADDED: "Imports added",
  EDIT_IMPORT: "Edit an import",
  IMPORT_UPDATED: "Imports updated",
  DELETE_THIS_IMPORT: "Delete this import ?",
  IMPORT_DELETED: "Imports deleted",
  IMPORT_MODEL: "Imports Model",
  CHOOSE_MODEL: "Choose an import model",
  IMPORT_NAME: "Nom de l'import",
  COLUMN_ROW_COUNT: "Nombre de ligne",
  SHOW_ROWS: "Imported Rows",
  COUNT_CSV_ROWS: "Number of rows",
};
